
import { Options, Vue } from "vue-class-component";
import { levelAPI } from "@/utils/apiCalls";
import { bundleLevelSettings, level } from "@/utils/Types";

@Options({
  data() {
    return {
      published: [],
    };
  },
  methods: {
    /**
     * Add level to bundle
     * @param i Bundle level settings
     */
    useLevel(i: bundleLevelSettings) {
      var index = this.published.indexOf(i, 0);
      this.$emit("usePublishedLevel", i);
      this.published.splice(index, 1);
    },
    /**
     * Remove published level
     * @param variable Bundle level settings
     */
    removePublishedLevel(variable: bundleLevelSettings) {
      this.published.push(variable);
    },
    /**
     * Update published levels
     * @param levels Bundle level settings list
     */
    async updatePublishedLevels(levels: bundleLevelSettings[]) {
      levelAPI.getAddableLevels().then((value) => {
        const levelSet = new Set(
          levels?.map((level: bundleLevelSettings) => level.levelId)
        );
        this.published = value?.filter(
          (level: level) => !levelSet.has(level.levelId)
        );
      });
    },
  },
  props: ["levels"],
})
export default class PublishedLevelsBlock extends Vue {}
