
import { Options, Vue } from "vue-class-component";
import { bundleAPI } from "@/utils/apiCalls";
import { bundle } from "@/utils/Types";
import { getUserInfo } from "@/utils/Functions";

@Options({
  data() {
    return {
      selected: null,
      public: false,
      bundles: [],
      publicBundles: [],
      publicDisplay: [],
      ownBundles: [],
      ownDisplay: [],
      overlay: false,
      moreDisplay: [],
      nameBundle: "",
      updateMessage: "",
      messageTitle: "",
      errorVisible: "",
    };
  },
  methods: {
    showMore(toggle: boolean) {
      this.public = toggle;
      if (toggle) {
        this.moreDisplay = this.publicDisplay;
        this.bundles = this.publicBundles;
      } else {
        this.moreDisplay = this.ownDisplay;
        this.bundles = this.ownBundles;
      }
    },
    /**
     * Remove bundle
     * @param id Bundle id
     */
    async removeBundle(id: number) {
      await bundleAPI.deleteBundle(id).then(async (err) => {
        if (err === null) {
          this.overlay = true;
          this.messageTitle = "Error";
          this.updateMessage = this.$t("general.error.lessonPackage.inUse");
        } else {
          await this.changeSelectedBundle("");
          await this.updateMyBundles();
          this.$emit("bundleRemoved");
        }
      });
    },
    /**
     * Request publication of bundle
     * @param id Bundle id
     */
    async requestPublication(id: number) {
      await bundleAPI.requestPublicationBundle(id);
    },
    /**
     * Publish bundle
     * @param id Bundle id
     */
    async publishBundle(id: number) {
      await bundleAPI.publishBundle(id).then(() => {
        this.updateMyBundles();
      });
    },
    /**
     * Clone bundle
     * @param id Bundle id
     */
    cloneBundle(id: number) {
      bundleAPI.cloneBundle(id).then((value) => {
        this.updateMyBundles();
        this.changeSelectedBundle(value);
      });
    },
    /**
     * Create new bundle
     * @param name Name of new bundle
     */
    async createNew(name: string) {
      if (name != "" && name.length <= 35) {
        bundleAPI.createBundle(name.toString()).then(async (value) => {
          this.updateMyBundles().then(() => {
            this.changeSelectedBundle(value);
          });
        });
        this.nameBundle = "";
      } else if (name.length > 35) {
        this.overlay = true;
        this.messageTitle = "Error";
        this.updateMessage = this.$t("general.error.lessonPackage.tooLong");
      } else {
        this.overlay = true;
        this.messageTitle = "Error";
        this.updateMessage = this.$t("general.error.lessonPackage.noName");
      }
    },
    //Update visible bundles
    async updateMyBundles() {
      await bundleAPI.getMyBundlesTeacher().then((value) => {
        this.ownBundles = value;
        this.bundles = this.ownBundles;
        this.moreDisplay = Array(this.bundles.length).fill("noMoreDisplay");
        this.ownDisplay = Array(this.bundles.length).fill("noMoreDisplay");
        this.public = false;
      });
      if (this.$store.getters.getUserInfo.permissionLevel == "admin") {
        await bundleAPI.getAddableBundles().then((value) => {
          this.publicBundles = value;
          this.publicDisplay = Array(this.publicBundles.length).fill(
            "noMoreDisplay"
          );
        });
      } else {
        await bundleAPI.getPublishedBundles().then((value) => {
          this.publicBundles = value;
          this.publicDisplay = Array(this.publicBundles.length).fill(
            "noMoreDisplay"
          );
        });
      }
    },
    /**
     * Change the selected bundle
     * @param b Bundle
     */
    changeSelectedBundle(b: bundle) {
      this.selected = b;
      this.$emit("updateBundle", b);
    },
    /**
     * Toggle visibilty bundle option buttons
     * @param i Number of bundle in the list
     */
    toggleMoreDisplay(i: number) {
      if (this.moreDisplay[i] === "noMoreDisplay") {
        this.moreDisplay[i] = "moreDisplay";
      } else {
        this.moreDisplay[i] = "noMoreDisplay";
      }
    },
    getUserInfo,
  },
  mounted() {
    this.updateMyBundles();
  },
  props: ["title"],
})
export default class ActiveBundlesBlock extends Vue {}
