
import { Options, Vue } from "vue-class-component";
import ActiveBundles from "@/components/lessonPackages/ActiveBundlesBlock.vue";
import PublishedLevels from "@/components/lessonPackages/PublishedLevelsBlock.vue";
import ChosenLevels from "@/components/lessonPackages/ChosenLevelsBlock.vue";
import { bundleLevelSettings, bundle } from "@/utils/Types";
import { getUserInfo } from "@/utils/Functions";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  mounted() {
    this.updateQuestionButton();
  },

  components: {
    ChosenLevels,
    ActiveBundles,
    PublishedLevels,
    QuestionButton,
  },
  data() {
    return {
      activeBundle: null,
      levels: null,
      wikiSubjects: [],
    };
  },
  methods: {
    //Reset active level when bundle is deleted
    bundleRemoved() {
      this.$refs.chosenLevels.active = null;
    },
    //Update active bundle when new bundle is selected
    updateMyBundles() {
      this.$refs.activeBundle.updateMyBundles();
    },
    /**
     * Add a published level to the bundle
     * @param variable Bundle level settings
     */
    usePublishedLevel(variable: bundleLevelSettings) {
      this.$refs.chosenLevels.addLevel(variable, this.activeBundle);
    },
    /**
     * Remove published level from bundle
     * @param variable Bundle level settings
     */
    removePublishedLevel(variable: bundleLevelSettings) {
      this.$refs.publishedLevels.removePublishedLevel(variable);
    },
    /**
     * Update current bundle
     * @param b Bundle
     */
    async updateBundle(b: bundle) {
      this.activeBundle = b;
      this.$refs.chosenLevels.activeName = b.name;
      this.$refs.chosenLevels.AllCodeClicked = false;
      this.$refs.chosenLevels.AllPsdClicked = false;
      await this.$refs.chosenLevels.updateBundleLevels(b).then(async () => {
        this.levels = this.$refs.chosenLevels.levels;
        await this.$refs.publishedLevels.updatePublishedLevels(this.levels);
      });
    },
    updateQuestionButton() {
      this.wikiSubjects.push(
        {
          wikiButton: { name: this.$t("wiki.packWiki"), item: "packWikiPage" },
        }
        //here you can add more page references
      );
    },
    getUserInfo,
  },
  computed: {
    /**
     * Determine if a bundle is selected
     * @returns Bool if selected
     */
    bundleSelected() {
      return this.activeBundle != null;
    },
  },
})
export default class LessonPackages extends Vue {}
