
import { Options, Vue } from "vue-class-component";
import { VueDraggableNext } from "vue-draggable-next";
import { bundleAPI } from "@/utils/apiCalls";
import { bundleLevelSettings, bundle } from "@/utils/Types";

@Options({
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      overlay: false,
      messageTitle: null,
      updateMessage: null,
      dragging: false,
      allDisplay: false,
      AllPsdClicked: false,
      AllCodeClicked: false,
      lockedClicked: false,
      active: "",
      activeName: null,
      moreDisplay: [],
      temp: [],
      levels: [] as bundleLevelSettings[],
    };
  },
  props: {
    bundleSelected: Boolean,
  },
  methods: {
    /**
     * Save bundle
     * @param newName Name of bundle
     */
    async commitBundle(newName: string) {
      for (let level of this.levels) {
        if (!level.allowPsd && !level.allowWritten) {
          this.messageTitle = this.$t("general.error.error");
          this.updateMessage = this.$t("general.error.lessonPackage.playable");
          this.overlay = true;
          return;
        }
      }
      if (newName == "") {
        this.messageTitle = this.$t("general.error.error");
        this.updateMessage = this.$t("general.error.lessonPackage.noName");
        this.overlay = true;
      } else if (newName.length > 35) {
        this.messageTitle = this.$t("general.error.error");
        this.updateMessage = this.$t("general.error.lessonPackage.tooLong");
        this.overlay = true;
      } else {
        this.levels.map((value: bundleLevelSettings, index: number) => {
          value.index = index;
          bundleAPI.updateBundleLevelSettings(value as bundleLevelSettings);
        });
        const bundle = {
          bundleId: this.active.bundleId,
          name: newName,
          lock: this.lockedClicked,
          published: false,
        };
        await bundleAPI.updateBundle(bundle).then((err) => {
          this.$emit("updateMyBundles", this.active.bundleId, newName);
          if (err === null) {
            this.overlay = true;
            this.messageTitle = "Error";
            this.updateMessage = this.$t(
              "general.error.lessonPackage.updateInUse"
            );
          } else {
            this.overlay = true;
            this.messageTitle = this.$t("general.success.success");
            this.updateMessage = this.$t(
              "general.success.lessonPackage.updated"
            );
          }
        });
      }
    },
    /**
     * Update visible bundles
     * @param b Bundle
     */
    async updateBundleLevels(b: bundle) {
      await bundleAPI.getBundleLevelSettings(b.bundleId).then((value) => {
        this.levels = value;
        this.active = b;
      });
      this.moreDisplay = Array(this.levels.length).fill("noMoreDisplay");
      this.lockedClicked = b.lock;
    },
    /**
     * Add level to bundle
     * @param l Bundle level settings
     * @param bundle Bundle
     */
    addLevel(l: bundleLevelSettings, bundle: bundle) {
      console.log("addlevel");
      var modePSD = false;
      var modeCode = false;
      if (this.AllCodeClicked) {
        modeCode = true;
      }

      if (this.AllPsdClicked) {
        modePSD = true;
      }

      this.moreDisplay.push("noMoreDisplay");
      const obj: bundleLevelSettings = {
        bundleId: bundle.bundleId,
        levelId: l.levelId,
        name: l.name,
        index: this.levels.length + 1,
        allowPsd: modePSD,
        allowWritten: modeCode,
      };
      this.levels.push(obj);
    },
    /**
     * Remove level from bundle
     * @param l Bundle level settings
     */
    removeLevel(l: bundleLevelSettings) {
      const index = this.levels.indexOf(l, 0);
      this.$emit("removePublishedLevel", l);
      this.levels.splice(index, 1);
      this.moreDisplay.splice(index, 1);
    },
    /**
     * Toggle visibilty bundle option buttons one level
     * @param i Number of bundle in the list
     */
    toggleMoreDisplay(i: number) {
      if (this.moreDisplay[i] === "noMoreDisplay") {
        this.moreDisplay[i] = "moreDisplay";
      } else {
        this.moreDisplay[i] = "noMoreDisplay";
      }
    },
    /**
     * Toggle selected code options for one level
     * @param i Number of bundle in the list
     */
    toggleSelectedCode(i: number) {
      for (let b in this.levels) {
        if (b) {
          this.AllCodeClicked = false;
        }
      }
      this.levels[i].allowWritten = !this.levels[i].allowWritten;
    },
    //Toggle selected code options for all levels
    toggleAllCode() {
      this.AllCodeClicked = !this.AllCodeClicked;
      this.levels.map((value: bundleLevelSettings) => {
        value.allowWritten = this.AllCodeClicked;
      });
    },
    /**
     * Toggle PSD code option for one level
     * @param i Number of bundle in the list
     */
    toggleSelectedPSD(i: number) {
      for (let b in this.levels) {
        if (b) {
          this.AllPsdClicked = false;
        }
      }
      this.levels[i].allowPsd = !this.levels[i].allowPsd;
    },
    //Toggle PSD code option for all levels
    toggleAllPSD() {
      this.AllPsdClicked = !this.AllPsdClicked;
      this.levels.map((value: bundleLevelSettings) => {
        value.allowPsd = this.AllPsdClicked;
      });
    },
    //Toggle visibilty bundle option buttons all levels
    toggleMoreDisplayAll() {
      this.allDisplay = !this.allDisplay;
      if (this.allDisplay) {
        this.moreDisplay = this.moreDisplay.map(() => "moreDisplay");
      } else {
        this.moreDisplay = this.moreDisplay.map(() => "noMoreDisplay");
      }
    },
  },
})
export default class ChosenLevelsBlock extends Vue {}
